import React from 'react';

const GridSection = () => {
  return (
    <section className="py-16 px-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-6" data-aos="fade-up">
        {/* Card 1 */}
        <div className="bg-purple-100 p-8 rounded-lg shadow" data-aos="fade-up">
          <h2 className="text-md text-[#d84eec] font-semibold mb-2">*15K downloads</h2>
          <h3 className="text-2xl font-semibold max-w-[20rem] my-4">Convenient & Advanced Features</h3>
          <p className="text-gray-700 mb-4 text-md">
          Instant Access: You can take, edit, and share photos all in one app, directly from your smartphone.
On-the-Go Editing: No need for a computer; photo apps allow you to edit and enhance images wherever you are.
Advanced Editing Features</p>
<p className="text-gray-700 mb-4 text-md">
Professional Editing Tools: Some apps offer advanced tools like exposure adjustment, color correction, and noise reduction for more precise edits.
Portrait and Beauty Enhancements: Apps can provide tools to smooth skin, adjust facial features, or even add makeup.</p>
        </div>

        {/* Card 2 */}
        <div className="bg-[#4a3aff] p-6 pt-6 pb-0 rounded-lg shadow flex items-center justify-center text-white" data-aos="fade-up">
          <div>
          <p className="text-center text-2xl max-w-[20rem] font-semibold">New iOS and Android apps coming soon</p>
            <img src="/images/top.png" alt="App Preview" className="mx-auto mt-4 rounded-lg transform skew-y-12" />
          </div>
        </div>

        {/* Card 3 */}
        <div className="bg-purple-100 p-8 rounded-lg shadow hidden md:block" data-aos="fade-down">
          <h3 className="text-2xl font-semibold mb-4">Creative Tools & EZ Sharing</h3>
          <p className="text-gray-700 mb-4 text-md">Collage Makers: Combine multiple photos into creative collages. Text and Stickers: Add text, captions, and stickers for personalization or fun.</p>
<p className="text-gray-700 mb-4 text-md">Drawing and Doodling: Many apps allow you to draw or annotate over your photos, adding a personal touch.
Easy Sharing: Social Media Integration.</p>
<img src='social_icons.png' className='h-24 right-0' data-aos="fade-down"></img>
        </div>

        {/* Card 4 */}
        <div className="bg-purple-100 px-6 pb-6 rounded-lg shadow z-0" data-aos="fade-down">
        <img src="/images/bottom.png" alt="Chart Preview" className="mx-auto -mt-7 rounded-lg transform skew-y-12" />
        </div>
      </div>
    </section>
  );
};

export default GridSection;