import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import HeroSection from '../components/HeroSection';
import GridSection from '../components/GridSection';
import MonstersSection from '../components/MonstersSection';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: false, // Whether animation should happen only once
      offset: 100
    });
  }, []);

  return (
    <div className="font-sans">
      <HeroSection />
      <GridSection />
      <MonstersSection />
    </div>
  );
};

export default Home;