// src/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';


function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="py-6">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
        
        <div className="mb-4 md:mb-0">
          <h1 className="text-2xl font-bold"><img src="/images/logo.svg" alt="footer logo"/></h1>
        </div>
        
        <div className="text-center mb-4 md:mb-0 text-[#7e7e7e]">
          <p>Copyright © {currentYear} theFotoApp. All rights reserved.</p>
        </div>
        
        {/* <div className="flex space-x-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <img src="/images/fb.svg" alt="facebook"/>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <img src="/images/dribal.svg" alt="facebook"/>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <img src="/images/instagram.svg" alt="facebook"/>
          </a>
        </div> */}
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </div>
    </footer>
  );
}

export default Footer;
