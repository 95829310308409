import React, { useState } from 'react';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className= "text-white md:bg-transparent bg-slate-50 p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-xl font-bold">
          <a href="/"><img src="images/logo.svg" alt="logo"/></a>
        </div>

        {/* Hamburger Icon */}
        <div className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
          <button className="text-black focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>

        {/* Links and Button */}
        <div id="mobile-nav"
          className={`${
            isOpen ? 'block' : 'hidden'
          } w-full md:flex md:items-center md:w-auto`}
        >
          <ul className="flex flex-col md:flex-row text-black md:gap-6">
            <li>
              <a href="/" className="block py-2 px-4 hover:text-[#6e40ff]">
               Home
              </a>
            </li>
            <li>
              <a href="#pricing" className="block py-2 px-4 hover:text-[#6e40ff]">
              Pricing
              </a>
            </li>
            <li>
              <a href="/contact" className="block py-2 px-4 hover:text-[#6e40ff]">
              Contact
              </a>
            </li>
          </ul>
          <div className="mt-4 md:mt-0 md:ml-4">
            <button className="bg-black hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full">
            Try for Free
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
