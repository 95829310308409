import React from 'react';

function TermsAndConditions() {
  return (
    <div className="font-sans py-24 px-4 bg-white">
      <h1 className="text-4xl font-semibold text-center mb-6">Terms and Conditions</h1>

      <section className="max-w-5xl mx-auto">
        <p className="text-md text-gray-700 mt-4">Last updated: November 15, 2024</p>
        <p className="text-md text-gray-700 mt-4">These Terms and Conditions ("Terms") govern your use of thefotoapp.com (the "Site"). By accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, you must not use the Site.</p>
        <h3 className="text-xl font-semibold mt-6">1. Acceptance of Terms</h3>

        <p className="text-md text-gray-700 mt-4">By accessing and using the Site, you agree to comply with these Terms and all applicable laws and regulations. If you do not agree to these Terms, you should immediately stop using the Site.</p>



        <h3 className="text-xl font-semibold mt-6">2. Changes to Terms</h3>
<p className="text-md text-gray-700 mt-4">
We may update these Terms from time to time. When we do, we will post the revised Terms on this page and update the "Last updated" date at the top. It is your responsibility to review these Terms regularly. Your continued use of the Site after any changes to the Terms constitutes your acceptance of the updated Terms.
</p>
<h3 className="text-xl font-semibold mt-6">3. Use of the Site</h3>

<p className="text-md text-gray-700 mt-4">You agree to use the Site only for lawful purposes and in accordance with these Terms. You must not:</p>
<p className="text-md text-gray-700 mt-4">
<ul className='list-disc pl-4'>
<li>Violate any applicable local, state, or national laws or regulations.</li>
<li>Use the Site for any fraudulent or illegal activity.</li>
<li>Attempt to gain unauthorized access to the Site or interfere with the proper functioning of the Site.</li>
<li>Use automated scripts or bots to access the Site.</li>
</ul>
</p>
<p className="text-md text-gray-700 mt-4">We reserve the right to terminate or suspend your access to the Site if you violate these Terms.</p>
<h3 className="text-xl font-semibold mt-6">4. Intellectual Property</h3>

<p className="text-md text-gray-700 mt-4">All content on the Site, including but not limited to text, graphics, logos, images, and software, is owned by the site or its licensors and is protected by copyright, trademark, and other intellectual property laws.</p>
<p className="text-md text-gray-700 mt-4">
You are granted a limited, non-exclusive, non-transferable license to use the Site for personal, non-commercial purposes. You may not reproduce, distribute, modify, or otherwise use the content of the Site without our prior written consent.
</p>
<h3 className="text-xl font-semibold mt-6">5. User Accounts</h3>
<p className="text-md text-gray-700 mt-4">
If you create an account on the Site, you agree to provide accurate, current, and complete information and to update your account as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
</p>
<p className="text-md text-gray-700 mt-4">
We reserve the right to terminate or suspend your account if we believe that you have violated these Terms.</p>

<h3 className="text-xl font-semibold mt-6">6. Privacy and Data Protection</h3>
<p className="text-md text-gray-700 mt-4">
Our Privacy Policy governs the collection, use, and sharing of your personal data. By using the Site, you agree to the practices outlined in our Privacy Policy.
</p>
<p  className="text-md text-gray-700 mt-4">
California Residents: Under the California Consumer Privacy Act (CCPA), you have specific rights regarding your personal information. For more details, refer to our Privacy Policy.
</p>
<p  className="text-md text-gray-700 mt-4">EU Residents: Under the General Data Protection Regulation (GDPR), you have rights regarding your personal data, including the right to access, rectify, delete, or restrict its processing. For more information, please refer to our Privacy Policy.</p>

<h3 className="text-xl font-semibold mt-6">7. Limitation of Liability</h3>

<p  className="text-md text-gray-700 mt-4">To the fullest extent permitted by law, [Insert Company Name] shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Site, including but not limited to loss of profits, loss of data, or business interruption, even if we have been advised of the possibility of such damages.</p>
<p  className="text-md text-gray-700 mt-4">This limitation of liability applies to the maximum extent permitted by applicable law, including California and EU laws.</p>


<h3 className="text-xl font-semibold mt-6">8. Indemnification</h3>

<p  className="text-md text-gray-700 mt-4">You agree to indemnify, defend, and hold harmless the site, its affiliates, employees, contractors, agents, and licensors from any claims, damages, liabilities, and expenses (including legal fees) arising out of your use of the Site or your violation of these Terms.</p>


<h3 className="text-xl font-semibold mt-6">9. Third-Party Links</h3>

<p  className="text-md text-gray-700 mt-4">The Site may contain links to third-party websites that are not operated or controlled by us. We are not responsible for the content, privacy policies, or practices of any third-party websites. By using the Site, you acknowledge and agree that we are not liable for any damages or losses arising from your use of third-party websites.</p>


<h3 className="text-xl font-semibold mt-6">10. Termination</h3>

<p  className="text-md text-gray-700 mt-4">We may terminate or suspend your access to the Site at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Site, us, or third parties.</p>


<h3 className="text-xl font-semibold mt-6">11. Governing Law</h3>

<p  className="text-md text-gray-700 mt-4">
These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the site is based, without regard to its conflict of law principles. If you are located in California, the laws of the State of California shall apply. If you are located in the EU, the laws of the country where you reside may apply.</p>


<h3 className="text-xl font-semibold mt-6">12. Dispute Resolution</h3>

<p  className="text-md text-gray-700 mt-4">
<ul className='list-disc pl-4'>
<li>
California Residents: If you are a resident of California, you may submit complaints to the California Department of Consumer Affairs. You may also be entitled to a right of arbitration or pursue legal action in a court of competent jurisdiction in California.
</li>
<li>EU Residents: If you are located in the European Union, you have the right to lodge a complaint with a supervisory authority in your country of residence regarding the processing of your personal data.</li>
</ul>
</p>

<h3 className="text-xl font-semibold mt-6">13. Severability</h3>

<p  className="text-md text-gray-700 mt-4">
If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>

<h3 className="text-xl font-semibold mt-6">14. Entire Agreement</h3>

<p  className="text-md text-gray-700 mt-4">
These Terms, together with our <a href='/privacy-policy'>Privacy Policy</a> and any other legal notices published on the Site, constitute the entire agreement between you and theFotoApp regarding the use of the Site.</p>
<h3 className="text-xl font-semibold mt-6">13. Severability</h3>

<p  className="text-md text-gray-700 mt-4">
If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>

<h3 className="text-xl font-semibold mt-6">15. Contact Us</h3>
<p  className="text-md text-gray-700 mt-4">
NextIQ Technologies<br/>

8903 Glades Road STE A8, Boca Raton, FL 33434, USA.<br/>

Email: contact@nextiqtech.com
</p>
      </section>
    </div>
  );
}

export default TermsAndConditions;