import React from 'react';

const MonstersSection = () => {
  return (
    <section className="my-10" data-aos="fade-up" id="pricing">
      <div className="text-center" data-aos="zoom-in">
        <h2 className="text-md text-[#d84eec] font-semibold mb-2">Concentrate with monsters</h2>
        <h3 className="text-4xl font-semibold m-auto max-w-[25rem] my-4">Complete Tasks and Unlock New Monsters</h3>
      </div>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1 md:col-span-4 bg-purple-100 rounded-lg" data-aos="fade-right">
            <img src="/images/dog.png" alt="Main" className="w-full h-auto object-cover" />
          </div>

          <div className="col-span-1 md:col-span-8 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 gap-4" data-aos="fade-left">
            {Array.from({ length: 8 }).map((_, index) => (
              <div key={index} className="w-full bg-purple-100 rounded-lg" data-aos="zoom-in">
                <img
                  src={`/images/dog.png`}
                  alt={`Image ${index + 1}`}
                  className="w-full h-auto object-cover filter grayscale hover:filter-none transition duration-300"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MonstersSection;