import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const HeroSection = () => {
  return (
    <section className="flex flex-col items-center text-center py-24 px-4 bg-white" data-aos="fade-up">
      <div className="flex items-center space-x-2 mb-4" data-aos="zoom-in">
        <img src="/images/users.png" alt="User list" />
        <span className="text-[#d84eec] font-medium text-lg">+20 Reviews</span>
      </div>

      <h1 className="md:text-5xl text-3xl font-bold hero-title mb-6" data-aos="fade-up">
        Stay Focused On Every Detail With <br /> Our Photo Tools.
      </h1>

      <div className="flex" data-aos="fade-up" data-aos-easing="linear">
        <button className="bg-[#6e40ff] hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-full flex items-center space-x-2 mb-8">
          <span>Download Now</span>
        </button>
        {/* <button className="bg-[#6e40ff] hover:bg-purple-700 text-white font-semibold py-2 px-4 ml-2 rounded-full flex items-center space-x-2 mb-8">
          <span>
            <FontAwesomeIcon icon={faDownload} />
          </span>
        </button> */}
      </div>

      <img src="/images/dog.png" alt="Dog" className="h-auto rounded-lg" data-aos="zoom-in-up" />
    </section>
  );
};

export default HeroSection;