import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Update the imports
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './Pages/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import ContactForm from './Pages/ContactForm';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes> {/* Replace Switch with Routes */}
        <Route path="/" element={<Home />} /> {/* Update the Route component */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Update the Route component */}
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> {/* Update the Route component */}
        <Route path="/contact" element={<ContactForm />} /> {/* Update the Route component */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;